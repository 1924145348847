<template>
  <div class="Counselor bg-white">
    <Row class="px-1">
      <i-col span="20" class="align-justify">
        <Button type="default" icon="md-refresh" size="large" class="mr-1" @click="refreshTable"></Button>
        <Button type="default" icon="md-add" size="large" class="mr-1" @click="AddSurveyTask"></Button>
        <i-input
          v-model="inputValue"
          size="large"
          style="width: 20rem"
          class="el-inline mr-1"
          placeholder="请输入发布名称或问卷名称进行查询"
        >
          <Button slot="append" icon="md-search" style="width: 2.5rem" @click="HandleSearch"></Button>
        </i-input>
        <Button size="large" class="mr-1" @click="batchRemove">批量删除</Button>
      </i-col>
      <i-col span="24">
        <Table
          border
          stripe
          ref="releaseTable"
          :loading="searching"
          :columns="tColumns"
          :data="tData"
          :height="theight"
          class="mt-1"
        >
          <div slot-scope="{row}" slot="action">
            <Button
              type="primary"
              size="small"
              style="margin-right: 0.25rem"
              @click="getReleaseDetail(row)"
            >详情</Button>
            <Button type="warning" size="small" @click="removeSurvey(row)">删除</Button>
          </div>
        </Table>
        <Page
          ref="usersPagination"
          :total="recordTotal"
          :page-size="20"
          @on-change="changePage"
          @on-page-size-change="changeSize"
          show-total
          show-sizer
          show-elevator
          class="p-1 align-right"
        />
      </i-col>
    </Row>
    <Drawer title="发布问卷" v-model="draw" width="600" :mask-closable="false">
      <Form ref="formValidate" :model="releaseInfo">
        <Row :gutter="32">
          <i-col span="24">
            <FormItem label="发布名称" label-position="top" prop="releaseName">
              <Input v-model="releaseInfo.releaseName" maxlength="50" show-word-limit />
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="32">
          <i-col span="24">
            <FormItem label="选择问卷" label-position="top" prop="surveyId">
              <Select v-model="releaseInfo.surveyId" filterable>
                <Option
                  v-for="item in surveyList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="32">
          <i-col span="24">
            <FormItem label="结束时间" label-position="top" prop="endTime">
              <DatePicker
                type="datetime"
                :options="dateoptions"
                :time-picker-options="{steps: [1, 60]}"
                v-model="releaseInfo.endTime"
                placeholder="选择日期与时间"
                style="width: 100%"
              ></DatePicker>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="32">
          <i-col span="24">
            <FormItem label="发布类型" label-position="top" prop="releaseType">
              <Select class="mr-1" v-model="releaseInfo.releaseType" @on-select="selectR_Type">
                <Option
                  v-for="item in releaseType"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="32" v-if="releaseInfo.releaseType == 0">
          <i-col span="24">
            <FormItem label="选择所属单位/部门" label-position="top" prop="units">
              <Cascader
                v-model="releaseInfo.units"
                :data="companyData"
                @on-change="checkCascader"
                change-on-select
              ></Cascader>
            </FormItem>
          </i-col>
        </Row>
      </Form>
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="cancelRelease">取消</Button>
        <Poptip confirm title="确定发布?" @on-ok="releaseTask">
          <Button type="primary">发布</Button>
        </Poptip>
      </div>
    </Drawer>
    <Drawer title="发布信息" v-model="detail" width="600" :mask-closable="false">
      <Form ref="formValidate" :model="releaseDetail">
        <Row :gutter="32">
          <i-col span="24">
            <FormItem label="发布名称" label-position="top" prop="releaseName">
              <Input v-model="releaseDetail.releaseName" maxlength="50" show-word-limit />
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="32">
          <i-col span="24">
            <FormItem label="选择问卷" label-position="top" prop="surveyId">
              <Select v-model="releaseDetail.surveyId" filterable>
                <Option
                  v-for="item in surveyList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="32">
          <i-col span="24">
            <FormItem label="结束时间" label-position="top" prop="endTime">
              <DatePicker
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                :options="dateoptions"
                :time-picker-options="{steps: [1, 60]}"
                v-model="releaseDetail.endTime"
                placeholder="选择日期与时间"
                style="width: 100%"
              ></DatePicker>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="32">
          <i-col span="24">
            <FormItem label="发布类型" label-position="top" prop="releaseType">
              <Select class="mr-1" v-model="releaseDetail.releaseType" @on-select="selectR_Type">
                <Option
                  v-for="item in releaseType"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="32" v-if="releaseDetail.releaseType == 0">
          <i-col span="24">
            <FormItem label="选择所属单位/部门" label-position="top" prop="units">
              <Cascader
                v-model="releaseDetail.units"
                :data="companyData"
                @on-change="checkCascader"
                change-on-select
              ></Cascader>
            </FormItem>
          </i-col>
        </Row>
      </Form>
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="cancelUpdate">取消</Button>
        <Poptip confirm title="确定修改?" @on-ok="updateRelease">
          <Button type="primary">修改</Button>
        </Poptip>
      </div>
    </Drawer>
  </div>
</template>

<script>
export default {
  name: "ReleaseSurvey",
  data() {
    return {
      detail: false,
      releaseDetail: {},
      dateoptions: {
        shortcuts: [
          {
            text: "明天",
            value() {
              const date = new Date();
              var y = date.getFullYear();
              var m = date.getMonth() + 1;
              m = m < 10 ? "0" + m : m;
              var d = date.getDate() + 1;
              d = d < 10 ? "0" + d : d;
              console.log(y + "-" + m + "-" + d);
              return y + "-" + m + "-" + d + "00:00:00";
            },
          },
        ],
        disabledDate(date) {
          return date && date.valueOf() < Date.now();
        },
      },
      optionsSetting: {},
      surveyList: [],
      companyData: [],
      releaseType: [
        {
          value: 1,
          label: "公开发布",
        },
        {
          value: 0,
          label: "定向发布",
        },
      ],
      releaseInfo: {
        releaseType: -1,
        units: [],
      },
      draw: false,
      tData: [],
      inputValue: "",
      searching: true,
      theight: window.innerHeight - 175,
      pageSize: 20,
      recordTotal: 0,
      pageNum: 1,
      tColumns: [
        {
          type: "selection",
          width: 35,
          align: "center",
        },
        {
          type: "index",
          title: "序号",
          width: 45,
          align: "center",
        },
        {
          title: "发布名称",
          key: "releaseName",
          minWidth: 50,
        },
        {
          title: "选取问卷",
          key: "surveyName",
          minWidth: 50,
        },
        {
          title: "收集数",
          key: "writeCount",
          minWidth: 50,
        },
        {
          title: "类型",
          key: "releaseType",
          minWidth: 50,
          render: (h, params) => {
            let dt = "";
            if (params.row.releaseType == 1) {
              dt = "公开发布";
            } else {
              dt = "定向发布";
            }
            return h("span", dt);
          },
        },
        {
          title: "发布时间",
          key: "releaseTime",
          align: "center",
          minWidth: 100,
          render: (h, params) => {
            let dt = this.myjs.dateTimeAdjust(params.row.releaseTime);
            return h("span", dt);
          },
          sortable: true,
          sortType: "desc",
        },
        {
          title: "截止时间",
          key: "endTime",
          align: "center",
          minWidth: 100,
          render: (h, params) => {
            let dt = this.myjs.dateTimeAdjust(params.row.endTime);
            return h("span", dt);
          },
          sortable: true,
          sortType: "desc",
        },
        {
          title: "发布人",
          key: "adminName",
          align: "center",
          minWidth: 140,
        },
        {
          title: "操作",
          slot: "action",
          width: 150,
          align: "center",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
    this.getReleaseList();
  },
  methods: {
    refreshTable() {
      this.inputValue = "";
      this.pageNum = 1;
      this.searching = true;
      this.getReleaseList();
    },
    cancelUpdate() {
      this.detail = false;
    },
    updateRelease() {
      console.log(this.releaseDetail);
    },
    getReleaseDetail(row) {
      this.getSurvey();
      this.getUnit();
      this.$get("Survey/GetReleaseDetail", {
        requestName: "GetReleaseDetail",
        releaseId: row.releaseSurveyId,
      }).then((res) => {
        console.log(res);
        let data = res.data.response
        this.releaseDetail = data;
        this.releaseDetail.units = [data.unit1Id, data.unit2Id, data.unit3Id];
      });
      this.detail = true;
      console.log(this.releaseDetail);
    },
    removeSurvey(row) {},
    getSurvey() {
      this.$get("Survey/GetSurveyListBySelect", {
        requestName: "getSurveyList",
      }).then((res) => {
        this.surveyList = res.data.responseList;
      });
    },
    getReleaseList() {
      this.$get("Survey/GetReleaseRecord", {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        keyword: this.inputValue,
      }).then((res) => {
        this.tData = res.data.responseList;
        this.searching = false;
      });
    },
    releaseTask() {
      if (this.releaseInfo.releaseType == 0) {
        this.releaseInfo.unit1Id = this.releaseInfo.units[0];
        this.releaseInfo.unit2Id = this.releaseInfo.units[1];
        this.releaseInfo.unit3Id = this.releaseInfo.units[2];
      }
      this.$set(this.releaseInfo, "releaseUser", 1);
      console.log(this.releaseInfo);
      let data = this.releaseInfo;
      this.$post("Survey/AddReleaseSurveyInfo", data).then((res) => {
        if (res.data.code) {
          this.$refs["formValidate"].resetFields();
          this.draw = false;
          this.$Message.success(res.data.msg);
        } else {
          this.$Message.error(res.data.msg);
        }
      });
    },
    cancelRelease() {
      this.draw = false;
    },
    selectR_Type(e) {
      this.releaseInfo.releaseType = e.value;
    },
    checkCascader(value, selectedData) {
      console.log(value);
    },
    getUnit() {
      this.$get("Customer/GetCompanyByCascader").then((res) => {
        this.companyData = this.filterArray(res.data.responseList);
      });
    },
    AddSurveyTask() {
      this.draw = true;
      this.getSurvey();
      this.getUnit();
    },
    HandleSearch() {
      this.getReleaseList();
    },
    batchRemove() {},
    changePage() {},
    changeSize() {},
    filterArray(list) {
      return list.map((item) => {
        item = Object.assign({}, item);
        if (item.level == 3) {
          delete item.children;
        } else {
          if (item.children && item.children.length > 0) {
            item.children = this.filterArray(item.children);
          }
        }
        return item;
      });
    },
  },
};
</script>
<style>
</style>